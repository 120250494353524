import dynamic from 'next/dynamic'

import { Stack, Typography } from '@mui/material'

import { AVAILABILITY_UNIQUE_KEY, SECTIONS_MAP } from '@/constants'
import { SectionLayout } from '@/layouts/SectionLayout'
import { useProduct } from '@/providers/p/ProductProvider'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'
import { useT } from '@/utils/frontend/useT'
import { isNotEmpty } from '@/utils/plain/isEmpty'

import { AvailabilityLabel } from '../../AvailabilityLabel'

const SPACING_4 = 4
const SPACING_3 = 3

const { id } = SECTIONS_MAP.variants
const dataCy = `VariantsSection`

const DynamicDetailsTable = dynamic(() =>
  import('../../DetailsTable/index').then((mod) => mod.DetailsTable),
)

const DynamicDetailsTableMobile = dynamic(() =>
  import('../../DetailsTable/DetailsTableMobile').then((mod) => mod.DetailsTableMobile),
)

const VariantsSection = () => {
  const { product } = useProduct()
  const { variants, name, variantsAvailabilityIcons } = product
  const { headers, data } = variants ?? {}

  const { t } = useT({ keyPrefix: 'product' })
  const { isDesktop } = useBreakpoints()

  if (!headers || !Array.isArray(headers) || !data || !Array.isArray(data)) {
    return null
  }

  const hasAvailabilityColumn = headers.some(({ key }) => key === AVAILABILITY_UNIQUE_KEY)

  return (
    <SectionLayout title={t('variants-section-title')} id={id} dataCy={dataCy}>
      <Stack
        spacing={isDesktop ? SPACING_4 : SPACING_3}
        direction={isDesktop ? 'column' : 'column-reverse'}
      >
        <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
          {isDesktop && (
            <Typography variant="h6" data-cy={`${dataCy}-subtitle`}>
              {name}
            </Typography>
          )}

          {hasAvailabilityColumn && isNotEmpty(variantsAvailabilityIcons) && (
            <Stack direction="row" spacing={3}>
              {variantsAvailabilityIcons?.map(({ iconLetter, translationLabel }) => {
                // @ts-ignore key should exist
                const label = t(`availability-labels.${translationLabel}`) as string
                const labelLetter = iconLetter ?? label?.[0]

                return (
                  <AvailabilityLabel
                    key={labelLetter}
                    label={label}
                    labelLetter={labelLetter as string}
                    dataCy={`${dataCy}-availabilityLabel-${iconLetter}`}
                  />
                )
              })}
            </Stack>
          )}
        </Stack>

        {isDesktop ? (
          <DynamicDetailsTable
            columns={headers}
            rows={data}
            isDesktop={isDesktop}
            dataCy={`${dataCy}-table`}
          />
        ) : (
          <DynamicDetailsTableMobile columns={headers} rows={data} dataCy={`${dataCy}-table`} />
        )}
      </Stack>
    </SectionLayout>
  )
}

export default VariantsSection
