import { blue } from '@knauf-group/ct-designs/themeConfigs/rebranding/colors'
import type { Theme } from '@mui/material'
import { Box, Stack, Typography } from '@mui/material'
import type { SystemStyleObject } from '@mui/system'

type AvailabilityLabelProps = {
  labelLetter: string
  label?: string
  dataCy?: string
}

const styles = {
  caption: {
    flex: 1,
  } as SystemStyleObject<Theme>,
  labelBox: {
    height: 16,
    width: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: blue[400],
    color: 'primary.contrastText',
  } as SystemStyleObject<Theme>,
}

export const AvailabilityLabel = (props: AvailabilityLabelProps) => {
  const { label, labelLetter, dataCy } = props

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box sx={styles.labelBox}>
        <Typography variant="caption" data-cy={`${dataCy}-labelLetter`}>
          {labelLetter}
        </Typography>
      </Box>
      {label && (
        <Typography variant="caption" sx={styles.caption} data-cy={`${dataCy}-label`}>
          {label}
        </Typography>
      )}
    </Stack>
  )
}
